import React, { useEffect, useState } from 'react';
import './App.scss';
import axios  from 'axios';

function App() {

  const [message, setMessage] = useState("");

  useEffect(() => {
      axios.get("/api/greeting").then(response => {
          setMessage(response.data);
      });
  }, []);


  return (
      <div className="App">
          <div style={{textAlign: "center"}}>
            <h1>WWW.BOLTEC.CZ</h1>
            <h2>{ message }</h2>
          </div>
      </div>
  );
}

export default App;
